//If updating update the relevant libraries in devops
//On deployment it does a key replacement on the file to get values
//Integration - https://dev.azure.com/codat/Codat/_library?itemType=VariableGroups&view=VariableGroupView&variableGroupId=406&path=codat-banking-ui-intg
//Production - https://dev.azure.com/codat/Codat/_library?itemType=VariableGroups&view=VariableGroupView&variableGroupId=407&path=codat-banking-ui-prod
window.AppConfig = {
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
  PORTAL_URL: "#{portalUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  BANKING_API_URL: "#{bankingApiUrl}#",
  USERS_API_URL: "#{usersApiUrl}#",
  LINK_API_URL: "#{linkApiUrl}#",
};
